// Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="branding">
          <h2>Moneytronix International Capital Partners, LLC</h2>
          <p>Connecting Real-World Assets with Blockchain Innovation</p>
        </div>
        <div className="footer-icons">
          {/* <a href="https://twitter.com" target="_blank" rel="noreferrer">Twitter</a>
          <a href="https://telegram.org" target="_blank" rel="noreferrer">Telegram</a>
          <a href="mailto:info@psmtxtoken.com">Email Us</a> */}
          <ul>
            <li>
              <a href="https://www.linkedin.com/company/mtx-gems/about/">
                <i className="fa-brands fa-linkedin" ></i>
              </a>

            </li>
            {/*

             <li>
              <i className="fa-brands fa-twitter"></i>
            </li>
            <li>
              <i className="fa-brands fa-instagram"></i>
            </li>
            <li>
              <i className="fa-brands fa-discord"></i>
            </li>
            <li>
              <i className="fa-brands fa-reddit-alien"></i>
            </li> */}
          </ul>
        </div>
        <div className='telecom'>
          {/* <span><i className="fa-solid fa-phone"> </i><strong> 1-800-MNYTRX</strong></span> */}
          <span><i className="fa-solid fa-envelope"> </i><strong> info@moneytronix.us</strong></span>
        </div>

        <p>&copy; 2024 Moneytronix. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;